import store from '../store'

function round(value, numDecimals) {

    //let isNegative = value < 0;
    //if (isNegative) value = value * -1;

    let result = Math.round(parseFloat(value) * Math.pow(10, numDecimals)) / Math.pow(10, numDecimals);

    //if (isNegative) result = result * -1;

    if (isNegative0(result)) return 0;

    return result;

}

function toLocale(value, numDecimals) {
    if (typeof numDecimals === 'undefined') numDecimals = 2;
    return round(value, numDecimals).toLocaleString(store.state.me.locale, {minimumFractionDigits: numDecimals});
}

function fromLocale(strValue) {
   
    strValue = strValue.toString();

    if (strValue === "") strValue = "0";

    if (["de-DE", "de-AT", "de-CH"].indexOf(store.state.me.locale) > -1) {
        strValue = strValue.replace(/\./, "");
        strValue = strValue.replace(/\,/, ".");
    } else {
        strValue = strValue.replace(/\,/, "");
    }

    return parseFloat(strValue);
}

function coloredToLocale(value, suffix) {
   
    if (value == Number.POSITIVE_INFINITY || value == Number.NEGATIVE_INFINITY) {
        return '<span style="opacity:.75">' + toLocale(value) + "</span>";
    }

    if (suffix) suffix = '&thinsp;' + suffix;

    if (value > 0) 
        return '<span style="color:green">+' + toLocale(value) + (suffix ||"") + "</span>";

    if (value < 0) 
        return '<span style="color:red">' + toLocale(value) + (suffix ||"") + "</span>";

    return '<span style="opacity:.25">± ' + toLocale(value) + (suffix ||"") + "</span>";
      
}

function isNegative0(x) {
    // http://www.wirfs-brock.com/allen/posts/128
    if (x!==0) return false;
    var obj=Object.freeze({z:-0});
    try {
       Object.defineProperty(obj,'z',{value:x});
    } catch (e) {return false};
    return true;
 }

export { toLocale, fromLocale, coloredToLocale };