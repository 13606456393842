<template>
  <v-container fluid class="mx-12 mt-1 mb-12">
    <v-row class="mx-0">
      <h1 class="mt-2 mb-2">{{ selected.forecast.title }} – Fremdkosten</h1>
      <v-col cols="2" class="d-flex">
        <v-select
          class="ml-4"
          label="Snapshot wählen"
          style="width: 164px; margin-top: 4px"
          v-model="selected.snapshot"
          :items="
            selected.forecast.snapshots
              .filter((s) => s.closed_at)
              .concat({ id: null, title: 'live' })
          "
          item-value="id"
          item-text="title"
          @change="fetchBudget"
          dense
          outlined
          prepend-inner-icon="mdi-calendar-star"
        ></v-select>
      </v-col>
      <v-spacer></v-spacer>

      <v-chip class="mt-5 mr-6"
        ><span class="mr-2">INT</span
        ><strong>{{ toLocale(getTotals.int) }} T€</strong></v-chip
      >
      <v-chip class="mt-5 mr-6"
        ><span class="mr-2">EXT</span>
        <strong>{{ toLocale(getTotals.ext) }} T€</strong></v-chip
      >
      <v-chip class="mt-5 mr-6" v-if="getTotals.notset > 0"
        ><span class="mr-2">???</span>
        <strong>{{ toLocale(getTotals.notset) }} T€</strong></v-chip
      >
      <v-chip class="mt-5 mr-6"
        ><span class="mr-2">GESAMT</span>
        <strong>{{ toLocale(getTotals.total) }} T€</strong></v-chip
      >
      <div class="pt-4">
        <Download
          v-if="current.permissions.FC_TENANT_EXPORT"
          :query="{ fcid: selected.forecast.id, tenant: current.id }"
          :defaults="{ report: 'costs' }"
        />
      </div>
    </v-row>
   
    <v-data-table
      ref="costsTable"
      :headers="headers"
      :items="costs"
      :search="search"
      :loading="loading"
      locale="de-DE"
      class="elevation-1"
      dense
      :items-per-page="25"
      :calculate-widths="true"
      :sort-by="['division']"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, -1],
        itemsPerPageText: 'Zeilen pro Seite',
      }"
    >
      <template v-slot:[`item.value`]="{ item }">
        <span>{{ toLocale(item.value) }}</span
        >&nbsp;<span>T€</span>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <span>{{ getType(item) }}</span>
      </template>

      <template v-slot:[`item.vendor`]="{ item }">
        <div v-if="current.permissions.COSTS_WRITE && selected.forecast.active">
          <v-select
            dense
            v-model="item.vendor_id"
            @change="setVendor(item.fcid)"
            :items="vendorlist"
            placeholder="Kreditor hinzufügen"
            hide-details
          >
          </v-select>
        </div>
        <div v-else>{{ getVendor(item) }}</div>
      </template>

      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} {{ trl("of") }}
        {{ items.itemsLength }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { toLocale } from "@/utils/numbers";
import { trl, getDivisionName, getClientName, getAttributeName } from "@/utils/strings";
import Download from "@/components/modals/Download";
import { mapGetters } from "vuex";

export default {
  name: "Costs",
  components: { Download },
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        {
          text: "Unit",
          value: "division",
          class: "primary white--text",
        },
        {
          text: "Kunde",
          value: "client",
          class: "primary white--text",
        },
        {
          text: "Kampagne",
          value: "title",
          class: "primary white--text",
        },
        {
          text: "Gattung",
          value: "attribute",
          class: "primary white--text",
        },
        {
          text: "Kostenart",
          value: "type",
          class: "primary white--text",
        },
        {
          text: "Kosten",
          value: "value",
          align: "end",
          class: "primary white--text",
        },
        {
          text: "Kommentar",
          value: "comment",
          class: "primary white--text",
        },
        {
          text: "Kreditoren-Konto",
          value: "vendor",
          class: "primary white--text",
        },
      ],
      dashboard: {
        int: 0,
        ext: 0,
        total: 0,
      },
      selected: {
        forecast: null,
        snapshot: null,
      },
      costs: [],
    };
  },
  methods: {
    toLocale,
    trl,
    getDivisionName,
    getClientName,
    getAttributeName,
    async fetchBudget() {
      this.loading = true;
      const params = {
        fcid: this.selected.forecast.id,
        tenant: this.current.id,
      };

      if (this.selected.snapshot) {
        params.snapshot = this.selected.snapshot;
      }

      const projects = await this.$store.dispatch("req", {
        route:
          "budget?" +
          Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&"),
      });

      this.loading = false;
      this.costs = [];
      projects
        .filter((p) => p.budget.costs !== null)
        .forEach((row) => {
          row.budget.costs.forEach((costItem) => {
            this.costs.push({
              fcid: row.budget.id,
              division: this.getDivisionName(row.division_id),
              client: this.getClientName(row.budget.client_id),
              title: row.budget.title,
              attribute: this.getAttributeName(row.budget.attribute_id),
              type: costItem.type,
              value: costItem.value,
              comment: costItem.comment,
              vendor: costItem.vendor,
              vendor_id: costItem.vendor_id,
            });
          });
        });
    },
    async setVendor(id) {
      let x = [];

      this.costs
        .filter((item) => item.fcid === id)
        .forEach((obj) => {
          x.push({
            value: obj.value,
            comment: obj.comment,
            vendor_id: obj.vendor_id || null,
          });
        });

      this.$store.dispatch("req", {
        route: "costs",
        data: { id: id, costs: x },
      });
    },
    getVendor(item) {
      let vendor = this.vendors.find((v) => v.id === item.vendor_id);

      if (!vendor) return;

      return vendor.name + " - " + (vendor.account_number || "");
    },
    getType(item) {
      let vendor = this.vendors.find((v) => v.id === item.vendor_id);

      if (!vendor) {
        return;
      }

      return vendor.type;
    },
    init() {

      (this.search = ""),
        (this.costs = []),
        (this.selected.forecast = this.forecasts.filter(
          (item) => item.id === this.$store.state.activeForecastId
        )[0]);

      this.fetchBudget();
    },
  },
  computed: {
    ...mapGetters([
      "current",
      "forecasts",
      "divisions",
      "clients",
      "attributes",
      "vendors",
    ]),
    vendorlist() {
      let items = [];
      this.vendors.forEach((v) =>
        items.push({
          text: v.name + " – " + (v.account_number || ""),
          value: v.id,
          disabled: !v.vis,
        })
      );
      return items;
    },
    getTotals() {
      const obj = {
        int: 0,
        ext: 0,
        notset: 0,
        total: 0,
      };

      this.costs.forEach((item) => {
        obj.total += item.value;

        let vendor = this.vendors.find((v) => v.id == item.vendor_id);
        if (!vendor) {
          obj.notset += item.value;
          return;
        }

        obj[vendor.type.toLowerCase()] += item.value;
      });
      return obj;
      return {
        int: this.costs
          .filter((item) => item.type === "INT")
          .reduce((accumulator, current) => accumulator + current.value, 0),
        ext: this.costs
          .filter((item) => item.type === "EXT")
          .reduce((accumulator, current) => accumulator + current.value, 0),
        notset: 0,
        total: this.costs.reduce(
          (accumulator, current) => accumulator + current.value,
          0
        ),
      };
    },
  },
  created() {
    this.init();
  },
  beforeRouteUpdate(to, from, next) {
    this.init();
    next();
  },
};
</script>

<style>
</style>